import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { parse } from '../lib/date_time'
import format from 'date-fns/format'
import slug from '../lib/slug'

export default function Template({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
    articles: { edges: article_nodes },
  },
}) {
  return (
    <Layout>
      <Helmet title={`${siteTitle} - Articles`} />
      <div id="pageheader">
        <h1>Articles</h1>
      </div>
      {article_nodes.map(
        (
          {
            node: {
              excerpt,
              frontmatter: { title, date },
            },
          },
          idx
        ) => (
          <article key={idx}>
            <h3>
              <Link to={`/articles/${slug(title)}`}>{title}</Link>
              <time>{format(parse(date), 'MMM dd yyyy')}</time>
            </h3>
            <p>{excerpt}</p>
            <Link
              to={`/articles/${slug(title)}`}
              className="btn btn-primary btn-lg"
            >
              Read More
            </Link>
          </article>
        )
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query allArticles {
    articles: allMarkdownRemark(
      sort: { order: DESC, fields: [] }
      limit: 1000
      filter: { fileAbsolutePath: { regex: "/articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 320)
          frontmatter {
            title
            date
            path
          }
        }
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
